import React, { useEffect } from "react";
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Footer";
import img1 from "../../assets/img/expr-counsellor.jpg";
import img2 from "../../assets/img/personalized-counsellor.png";
import img3 from "../../assets/img/quality.png";
import img4 from "../../assets/img/immediteservice.png";
import AOS from "aos";
import GetCounsellingForm from "../components/Common/GetCounsellingForm";
import howimg1 from "../../assets/img/calendar-icon-free-vector.jpg";
import howimg2 from "../../assets/img/personalized-counsellor.png";
import howimg3 from "../../assets/img/counsellor.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { schemaMarkup } from "../../config";

const BookNow = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      delay: 300,
    });
    // setTimeout(() => {
    //   window.scrollTo({
    //     top: (window.innerWidth < 768) ? 1800 : 950,
    //     behavior: "smooth"
    //   })
    // }, 1000)
  }, []);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href='https://www.jeantherapyonline.co.uk/get-counselling' />
        <title> Private Online Counselling & Therapy for Anxiety, Marriage, Relationships, Grief & Mental Health</title>
        <meta name="description" content="Book counselling sessions through Jean Therapy Online to be matched with a therapist tailored to your needs from marriage and relationship counselling to mental health therapy." />
      </Helmet>
      <div className="overflow">
        <div className="page-container">
          <Navbar />

          <section className="py-5">
            <div className="px-md-5 px-4">
              <div className="text-section" data-aos="fade-in">
                <div className="text2 lh-sm mb-4 text-center mx-auto">
                  Personalised Counselling with Experienced Therapists at Jean Therapy Online in the UK
                </div>
                <div className="text1 fs-4 fw-semibold text-center">
                  Get Counselling
                </div>
                <div className="text3">
                  <div className="mb-4">
                    <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
                    If you're seeking professional, private therapy for anxiety, relationship challenges, grief, or mental health concerns, Jean Therapy offers tailored counselling services designed to meet your unique needs. Our online counselling, available throughout the UK, provides you with convenient access to experienced therapists from the comfort of your own home.
                  </div>
                  <div className="mb-4">
                    <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
                    Whether you're struggling with anxiety, navigating the complexities of marriage, or seeking support for grief or mental health issues, our personalised therapy services are here to guide you towards healing and personal growth. Each session is tailored specifically to your circumstances, ensuring that the support you receive is relevant and effective.
                  </div>
                  <div className="mb-4">
                    <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
                    Our professional team of counsellors is dedicated to providing a safe, confidential space for you to explore your feelings, address challenges, and work towards lasting solutions. From relationship struggles to anxiety management, we aim to help you regain a sense of balance and well-being.{" "}
                  </div>
                  <div className="mb-4">
                    <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
                    With flexible online sessions and a focus on your individual needs, Jean Therapy is committed to offering the support you need, wherever you are in the UK. Take the first step towards a healthier, happier mind by booking your private, personalised counselling session today.
                  </div>
                </div>
                {/* <div className="application-btn text-center">
                  <button onClick={() => navigate(`${Config.appUrl}book-a-session`)} >Get Counselling Now <i className="bi bi-arrow-right ms-3"></i> </button>
                </div> */}
              </div>

            </div>
          </section>

          <div>
            <div className="book-now d-md-flex justify-content-around align-items-center" data-aos="fade-in">
              <h1 className="mb-0">Get Your Counselling</h1>
            </div>
          </div>
          <div className="customer-form position-relative up-form" id="booknowform">
            <GetCounsellingForm />
          </div>

          <div className="booknow-container">
            <div className="bg-white">
              <div className="getstrted">
                <div className="start-div" data-aos="fade-in">
                  <img loading="lazy" src={img1} alt="Experienced Counsellors and Therapists"></img>
                  <div className="div-title">
                    Experienced Counsellors and Therapists
                  </div>
                  <div className="div-text">
                    All our therapists have no less than 2 years in their chosen
                    field and have done more than 150 hours in their practice.
                  </div>
                </div>
                <div className="start-div" data-aos="fade-in">
                  <img loading="lazy" src={img2} alt="personalised Counselling"></img>
                  <div className="div-title">Personalised Counselling</div>
                  <div className="div-text">
                    Your therapist will be matched by your goals and presenting
                    issue, if you find you would like to change therapist for
                    any reason this can and will be done at your request.
                  </div>
                </div>
                <div className="start-div" data-aos="fade-in">
                  <img loading="lazy" src={img4} alt="Quality and Safety"></img>
                  <div className="div-title">Quality and Safety</div>
                  <div className="div-text">
                    Jean therapy prides itself on the quality of its service and
                    all therapists are full qualified, insured and registered or
                    accredited with a regulatory body.
                  </div>
                </div>
                <div className="start-div" data-aos="fade-in">
                  <img loading="lazy" src={img3} alt="Immediate Service"></img>
                  <div className="div-title">Immediate Service</div>
                  <div className="div-text">
                    You will be connected with a therapist within 24 hours no
                    more long waiting lists.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div style={{ height: "150px", overflow: "hidden" }}>
            <svg
              viewBox="0 0 500 150"
              preserveAspectRatio="none"
              style={{ height: "100%", width: "100%" }}
            >
              <path
                d="M-1.41,52.80 C159.99,147.53 271.16,-29.12 499.72,70.57 L500.00,0.00 L0.00,0.00 Z"
                style={{ stroke: "none", fill: "#a1dbf1" }}
              ></path>
            </svg>
          </div>
        </div>
        <div className="booknow-howitwork">
          <div className="howitwork-title" data-aos="fade-in">
            <h2>How It Works</h2>
            <hr />
          </div>
          <div className="howitwork-content">
            <div className="howitwork-box" data-aos="fade-in">
              <img loading="lazy" src={howimg1} alt="How its work"></img>
              <div>
                Choose which therapy plan works for you and pay with our secure
                payment system. If you would like details of bookings for
                insurance please email &nbsp;
                <a
                  href="mailto:info@jeantherapyonline.co.uk"
                  style={{ textDecoration: "none", color: "#00b6bc" }}
                >
                  info@jeantherapyonline.co.uk
                </a>
              </div>
            </div>
            {/* <i className="bi bi-arrow-right"></i> */}
            <div className="howitwork-box" data-aos="fade-in">
              <img loading="lazy" src={howimg2} alt="How its work"></img>
              <div>
                Choose your Therapist/Counsellor and schedule a session from
                their personal calendar, if you choose the monthly plan, you
                could book the 4 sessions all at once so you are in control of
                your own sessions from the start.
              </div>
            </div>
            {/* <i className="bi bi-arrow-right"></i> */}
            <div className="howitwork-box" data-aos="fade-in">
              <img loading="lazy" src={howimg3} alt="How its work"></img>
              <div>
                Attend session at the allotted time. A link will be sent to the email provided upon sign up.
              </div>
            </div>
          </div>
        </div>

        {/* <SvgDivider/> */}

        {/* <div className="customer-form" id="booknowform">
          <GetCounsellingForm />
        </div> */}

        {/* <ReviewSlider/> */}

        <Footer />
      </div>
    </>
  );
};
export default BookNow;
