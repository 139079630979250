import React from "react";
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from "react";
import owner_img from "../../assets/img/about-us.jpg";
import missionimg from "../../assets/img/MisionImg.png";
import visionimg from "../../assets/img/VisionImg.png";
import logo1 from "../../assets/img/quality.png";
import logo2 from "../../assets/img/competence.png";
import logo3 from "../../assets/img/compassion.png";
import SvgDivider from "../components/Common/SvgDivider";
import ImageSlider from "../components/Common/ImageSlider";
import { Helmet } from "react-helmet";
import { schemaMarkup } from "../../config";

function AboutUs() {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const pageName = "aboutus";


  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      delay: 300,
    });
  });
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href="https://www.jeantherapyonline.co.uk/aboutus" />
        <title> About Jean Therapy</title>
        <meta name="description" content="Find out more about the team at Jean Therapy and what we do. We aim to provide comprehensive and compassionate mental health support through accessible therapy services." />
      </Helmet>
      <div className="page-container">
        <div className="page-content">
          <Navbar pageName={pageName} />
          <div className="about-text">
            <div className="about-main" data-aos="fade-in">
              <div className="about-container">
                <h1>About Us</h1>
              </div>
            </div>
          </div>

          <div>
            <div className="therapy-info ">
              <div className="therapy-info1 bg-white align-items-center">
                <div className="image-container" data-aos="fade-right">
                  <img src={owner_img} alt="Jeantherapyonline Logo" />
                </div>
                <div className="content-container">
                  <span data-aos="fade-up">
                    Private mental health consultation from a professional
                  </span>
                  <div className="para-content" data-aos="fade-left">
                    <div>
                      <i className="bi bi-check2-all"></i>Therapy is a
                      transformative journey towards understanding oneself,
                      navigating life's challenges, and fostering personal
                      growth.
                    </div>
                    <div>
                      <i className="bi bi-check2-all"></i>It provides a safe and
                      confidential space where individuals can explore their
                      thoughts, emotions, and behaviours with the guidance of a
                      qualified professional.
                    </div>
                    <div>
                      <i className="bi bi-check2-all"></i>Therapy is not just
                      for addressing mental health issues like anxiety,
                      depression, or trauma; it's also a powerful tool for
                      enhancing self-awareness, improving relationships, and
                      developing coping strategies for life's complexities.
                    </div>
                    <div>
                      <i className="bi bi-check2-all"></i>Whether seeking
                      support during difficult times, striving for
                      self-improvement, or simply desiring a deeper
                      understanding of oneself, therapy offers a collaborative
                      and empowering process tailored to each individual's
                      unique needs and goals.
                    </div>
                    <div>
                      <i className="bi bi-check2-all"></i>All our therapists are
                      insured and registered/accredited with a governing body
                      details can be presented on request. You will not find an
                      unqualified or unregistered therapist on our platform we
                      pride ourselves on our ethical practice here at Jean
                      Therapy Online.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="bg-blue">
            <div className="our-team">
              <div className="title" data-aos="fade-up">
                <h1>Our Team</h1>
              </div>
              <div className="para" data-aos="fade-up">
                <p>
                  We are passionate and compassionate professionals, driven by
                  the mission of helping more people live a better and happier
                  life every day. We are growing fast and always looking for new
                  talent. If you love people and like challenges - come and join
                  us!
                </p>
              </div>
              <div data-aos="fade-left">
                <ImageSlider />
                {/* <Slider {...settings}>
                                {psychologists.map((psychologist, index) => (
                                    <div key={index} className="col-md-5 px-4 pad-4">
                                        <div className="review-box1 p-4">
                                        <NavLink to={`${Config.appUrl}therapistdetail/${psychologist.slug}`}>
                                                <div className="slider-img-container">
                                                    <img src={psychologist.image} alt={psychologist.name} className="slider-img" />
                                                    <div className="overlay">
                                                        <span>View Profile</span>
                                                    </div>
                                                </div>
                                                
                                            </NavLink>
                                            <div className="slider-text">
                                                    <h5>{psychologist.name}</h5>
                                                    <h6>{psychologist.title}</h6>
                                                </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider> */}
              </div>
            </div>
          </section>

          <div className="bg-white">
            <div className="">
              <div style={{ height: "150px", overflow: "hidden" }}>
                <svg
                  viewBox="0 0 500 150"
                  preserveAspectRatio="none"
                  style={{ height: "100%", width: "100%" }}
                >
                  <path
                    d="M-1.98,82.40 C236.74,-40.95 293.74,149.50 500.85,46.88 L500.28,-0.50 L0.00,0.00 Z"
                    style={{ stroke: "none", fill: "#e4f4f3" }}
                  ></path>
                </svg>
              </div>
            </div>
            <section className="about-content">
              <div className="bg-white">
                <div className="about-flex" data-aos="fade-up">
                  <h1>Find yourself in therapy</h1>
                  <div>
                    "Welcome to our online therapy portal, where healing begins
                    with a click. Our platform offers a safe, convenient, and
                    confidential space for individuals seeking support on their
                    journey to mental wellness. Whether you're navigating
                    anxiety, depression, relationship challenges, or simply
                    seeking personal growth, our team of qualified therapists is
                    here to guide you with empathy, expertise, and
                    understanding. With flexible scheduling, secure and
                    confidential video sessions, access to professional help is
                    just a few clicks away. Take the first step towards a
                    happier, healthier you with Jean Therapy Online”.
                  </div>
                </div>
              </div>
            </section>

            <div className="our-mission">
              <div className="ourmission-div" data-aos="fade-in">
                <div className="ourmission-img">
                  <img src={missionimg} alt="Our Mission"></img>
                </div>
                <div className="ourmission-text">
                  <h2>OUR MISSION</h2>
                  <span>
                    Our mission at Jean Therapy Online is to provide
                    comprehensive and compassionate mental health support
                    through innovative and accessible therapy services. We are
                    dedicated to connecting individuals with professional,
                    empathetic therapists, fostering a safe space for healing,
                    growth, and personal development. Together, we aim to
                    empower every user to achieve their full potential and lead
                    a fulfilling life.
                  </span>
                </div>
              </div>
              <div className="ourmission-div" data-aos="fade-in">
                <div className="ourmission-img">
                  <img src={visionimg} alt="Our Vission"></img>
                </div>
                <div className="ourmission-text">
                  <h2>OUR VISION</h2>
                  <span>
                    At Jean Therapy Online, our vision is to revolutionise
                    mental health care by making it universally accessible and
                    deeply personal. We strive to become the go-to platform for
                    emotional and psychological support, ensuring that every
                    individual can easily connect with the right therapist and
                    receive the care they deserve. Our ultimate goal is to
                    foster a world where mental health is prioritised ,
                    supported, and nurtured, empowering individuals to thrive in
                    all aspects of their lives.
                  </span>
                </div>
              </div>
            </div>
            <SvgDivider />
          </div>

          <div className="bg-blue">
            <div className="whyjean">
              <div className="why-title" data-aos="fade-in">
                <h1>Why Jean Therapy</h1>
                <span>
                  In today's fast-paced world, mental health is more important
                  than ever. Our platform offers a safe, supportive space where
                  you can take charge of your mental well-being. By joining us,
                  you'll have access to expert guidance from qualified mental
                  health professionals, connect with a compassionate community
                  that understands your journey, and utilize a range of
                  personalised tools and activities tailored to your unique
                  needs. With 24/7 accessibility, support is always available
                  whenever you need it. Plus, your privacy is our priority,
                  ensuring you can share and seek help without any worries. Take
                  the first step towards a healthier, happier you by joining us
                  today and start your journey to better mental health
                  well-being.
                </span>
              </div>
              <div className="why-content">
                <div className="box-content" data-aos="fade-in">
                  <div>
                    <img src={logo1} alt="Quality"></img>
                  </div>
                  <span className="why-content-text">Quality</span>
                  <span>
                    Our platform is dedicated to delivering top-quality mental
                    health support, featuring expert guidance from qualified
                    professionals, a compassionate and understanding community,
                    and personalised tools tailored to your needs. With 24/7
                    accessibility and robust privacy measures, you can trust us
                    to provide a secure and effective environment for your
                    mental well-being.
                  </span>
                </div>
                <div className="box-content" data-aos="fade-in">
                  <div>
                    <img src={logo2} alt="Competence"></img>
                  </div>
                  <span className="why-content-text">Competence</span>
                  <span>
                    Our mental health platform is built on a foundation of
                    competence, offering expert guidance, evidence-based
                    resources, and personalised tools tailored to your unique
                    needs. We ensure 24/7 accessibility and maintain the highest
                    standards of privacy and security, providing a reliable and
                    effective environment for your mental well-being.
                  </span>
                </div>
                <div className="box-content" data-aos="fade-in">
                  <div>
                    <img src={logo3} className="compassion"></img>
                  </div>
                  <span className="why-content-text">Compassion</span>
                  <span>
                    Our platform embodies compassion, providing expert guidance
                    from qualified professionals and a supportive community that
                    truly understands your journey. We offer personalised tools
                    tailored to your needs, 24/7 accessibility, and stringent
                    privacy protections to ensure a secure and nurturing
                    environment for your mental well-being. Join us today to
                    experience the care and compassion you deserve.
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="bg-white">
                <div className="">
                    <div style={{ height: '150px', overflow: 'hidden' }} >
                        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                            <path d="M-0.28,76.48 C205.70,-42.93 298.25,151.48 503.10,41.93 L500.00,0.00 L0.00,0.00 Z" style={{ stroke: 'none', fill: '#e4f4f3' }}>
                            </path>
                        </svg>
                    </div>
                </div>
                    <div className="reviews">
                        <h1 data-aos="fade-up">Our Latest Reviews</h1>
                        <div className="review-tab">
                            <div className="reviewtab1" data-aos="fade-right">
                                <div className="reviewimg">
                                    <img src={peopleimg1}></img>
                                </div>
                                <div className="text">
                                    "She has helped us with just simple life altering things that help us be more functional!"
                                </div>
                                <div className="review-for">
                                    - Written 1 day ago for Brandi Sewell (LPC)
                                </div>
                            </div>
                            <div className="reviewtab1" data-aos="fade-up">
                                <div className="reviewimg">
                                    <img src={peopleimg2}></img>
                                </div>
                                <div className="text">
                                    "She has helped us with just simple life altering things that help us be more functional!"
                                </div>
                                <div className="review-for">
                                    - Written 1 day ago for Brandi Sewell (LPC)
                                </div>
                            </div>
                            <div className="reviewtab1" data-aos="fade-left">
                                <div className="reviewimg">
                                    <img src={peopleimg3}></img>
                                </div>
                                <div className="text">
                                    "She has helped us with just simple life altering things that help us be more functional!"
                                </div>
                                <div className="review-for">
                                    - Written 1 day ago for Brandi Sewell (LPC)
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

          {/* <section className="pt-5">
                <div className="container-box text-md-start text-cpy-md-5 mt-5">
                    <h2 className="display-5 text-white" data-aos="fade-up">
                        Frequently Asked Questions
                    </h2>

                    <div className="mt-4 pb-2" data-aos="fade-up">
                        <div className="accordion accordion-flush accordian-transparent" id={`accordionFlushExample`}>
                            {
                                data.slice(0, accordianAll ? 18 : 3).map((item, i) => {
                                    return (
                                        <div key={i} className="accordion-item bg-transparent">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button text-white collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseThree${i}`} aria-expanded="false" aria-controls={`flush-collapseThree${i}`}>
                                                    <div className='index rounded-circle bg-blue text-black me-3'>{i + 1}</div> {item.title}
                                                </button>
                                            </h2>
                                            <div id={`flush-collapseThree${i}`} className={`accordion-collapse collapse ${i == 0 && 'show'}`} data-bs-parent={`#accordionFlushExample`}>
                                                <div className="accordion-body">
                                                    <p className="paragraph text-light">
                                                        {item.desc}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className="mt-5 text-end">
                        <button
                            className="button bg-btn2 mb-5"
                            onClick={() => setAccordianAll(!accordianAll)}
                        >
                            Show {accordianAll ? 'Less' : 'More'}
                            <i className={`bi bi-arrow-${accordianAll ? 'up' : 'down'} ms-2`}></i>
                        </button>
                    </div>
                </div>


            </section> */}

          {/* <section className="bg-white">
                <div className="our-team" >
                    <div className="title" data-aos="fade-up">
                        <h1>Our Team</h1>
                    </div>
                    <div className="para" data-aos="fade-up">
                        <p>We are passionate and compassionate professionals, driven by the mission of helping more people live a better and happier life every day. We are growing fast and always looking for new talent. If you love people and like challenges - come and join us!
                        </p>
                    </div>
                    <div data-aos="fade-left">
                        <Slider {...settings}>
                            <div className="col-md-6 px-2">
                                <div className="bg-blue">
                                    <div className='slider-img'>
                                        <img src={peopleimg1}></img>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 px-2">
                                <div className="bg-blue">
                                    <div className='slider-img'>
                                        <img src={peopleimg2}></img>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 px-2">
                                <div className="bg-blue">
                                    <div className='slider-img'>
                                        <img src={peopleimg3}></img>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 px-2">
                                <div className="bg-blue">
                                    <div className='slider-img'>
                                        <img src={peopleimg4}></img>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 px-2">
                                <div className="bg-blue">
                                    <div className='slider-img'>
                                        <img src={peopleimg2}></img>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section> */}

          <Footer />
        </div>
      </div>
    </>
  );
}
export default AboutUs;
